import React from "react";
import Landing01 from "src/components/landing/Landing01";
import 'src/css/font.css';

function Landing() {
    return (
        <div>
            <Landing01 />
        </div>
    );
}

export default Landing;