import React from "react";
import './pricing.css';
import FeatureRow from "./FeatureRow";


function PricingTable() {
  return (
    <table className="pricing_01__content__bottom__table table-1">
      <tr>
        <th></th>
        <th className="pricing_01__content__bottom__card">
          <p className="pricing_01__content__bottom__card__title">Tier 1</p>
        </th>
        <th className="pricing_01__content__bottom__card">
          <p className="pricing_01__content__bottom__card__title">Tier 2</p>

        </th>
        <th className="pricing_01__content__bottom__card">
          <p className="pricing_01__content__bottom__card__title">Tier 3</p>
        </th>
        <th className="pricing_01__content__bottom__card">
          <p className="pricing_01__content__bottom__card__title">Custom Solutions</p>
        </th>
      </tr>
      <FeatureRow title="Monthly Cost" firstCheck="" first="$495" secondCheck="" second="$1,995" thirdCheck="" third="$4,995" fourthCheck="" fourth=" Have Larger Volume Minutes?" />
      <FeatureRow title="Minutes Included" firstCheck="" first="1,500" secondCheck="" second="7,000" thirdCheck="" third="17,000" fourthCheck="" fourth=" Can You Build Me An AI Solution I Own?" />
      <FeatureRow title="Additional Usage per 15 secs" firstCheck="" first="$0.07" secondCheck="" second="$0.07" thirdCheck="" third="$0.07" fourthCheck="" fourth=" Want More Customization?" />
      <tr>
        <td className="pricing_01__content__bottom__card"></td>
        <td className="pricing_01__content__bottom__card">
          <a href="https://checkout.allriseai.com/tier-1" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Sign Up</a>
        </td>
        <td className="pricing_01__content__bottom__card">
          <a href="https://checkout.allriseai.com/tier-2" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Sign Up</a>
        </td>
        <td className="pricing_01__content__bottom__card">
          <a href="https://checkout.allriseai.com/tier-3" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Sign Up</a>
        </td>
        <td className="pricing_01__content__bottom__card">
          <b>
            Multiple Products Or Services?
          </b>
        </td>
      </tr>
      <tr>
        <td className="pricing_01__content__bottom__card"></td>
        <td className="pricing_01__content__bottom__card"></td>
        <td className="pricing_01__content__bottom__card"></td>
        <td className="pricing_01__content__bottom__card"></td>
        <td className="pricing_01__content__bottom__card">
          <a href="https://allriseai.com/contact" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Contact Us</a>
        </td>

      </tr>
    </table>
  );
}

export default PricingTable;