import React from "react";
import './landing.css';
import { Reveal } from "../common/Reveal";
import Footer from "../common/Footer/Footer";
import PricingTable from "../pricing/PricingTable"
import Calculator from "../pricing/Calculator"
import AudioBtn from "../home/AudioBtn";
import HomeAudio from "../../audio/home.wav";
import LegalAudio from "../../audio/legal.mp3";
import InsuranceAudio from "../../audio/insurance.mp3";
import FinancialAudio from "../../audio/financial.mp3";

function Landing01() {
  const [firstVideoExpanded, setFirstVideoExpanded] = React.useState(false);
  const [secondVideoExpanded, setSecondVideoExpanded] = React.useState(false);
  const isVideoExpanded = firstVideoExpanded || secondVideoExpanded;
  const hidden1 = {
    opacity: 0,
    y: 75
  }
  const visible1 = {
    opacity: 1,
    y: 0
  }

  const hidden2 = {
    left: 0
  }

  const visible2 = {
    left: "100%"
  }

  function Overlay() {
    return (
      <div className=""
        onClick={() => { setFirstVideoExpanded(true); setSecondVideoExpanded(false); }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: isVideoExpanded ? "0px" : "100%",
          height: isVideoExpanded ? "0px" : "100%",
          cursor: "pointer",
          backgroundColor: "transparent",
        }}
      />
    )
  }

  return (
    <div id="landing">
      <div className="landing_01">
        <div className="container">
          <div className="landing_01__video_section">
            <div className="landing_01__video__title__row">
              <div className="landing_01__video__title__text">
                Client Experiences a 10x Increase in Conversion
              </div>
              <div
                className="landing_01__demo__text__container__info__box"
                onClick={() => { setFirstVideoExpanded(true); setSecondVideoExpanded(false); }}
              >
                Watch Now
              </div>
              <div className="landing_01__video__title__line" />
            </div>
            <div className="landing_01__video__row">
              <div
                className={`landing_01__video__container 
                  ${firstVideoExpanded ? "expanded" : ""} 
                  ${secondVideoExpanded ? "hidden" : ""}`}
              >
                <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", maxHeight: "600px", borderRadius: "30px", overflow: "hidden", }} >
                  <div className="landing_01__video">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/fnsljvsLRIs?si=3UVuM0yKdwiwQrCS&controls=2&rel=0&showinfo=0&modestbranding=1"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                      referrerpolicy="strict-origin-when-cross-origin"
                    />
                    <Overlay />
                  </div>
                </div>
              </div>
              <div className={`landing_01__video__divider ${isVideoExpanded ? "expanded" : ""}`} />
              <div
                className={`landing_01__video__container 
                ${secondVideoExpanded ? "expanded" : ""} 
                ${firstVideoExpanded ? "hidden" : ""}`}
              >
                <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", maxHeight: "600px", borderRadius: "30px", overflow: "hidden", }} >
                  <div className="landing_01__video">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/5zWwsz2ACrU?si=R-wnSAnh_-3WF9W2&controls=2&rel=0&showinfo=0&modestbranding=1"
                      title="All Rise Ai Demo Video"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                      referrerpolicy="strict-origin-when-cross-origin"
                    />
                    <Overlay />
                  </div>
                </div>
              </div>
            </div>
            <div className="landing_01__video__title__row">
              <div className="landing_01__video__title__line" />
              <div
                className="landing_01__demo__text__container__info__box"
                onClick={() => { setFirstVideoExpanded(false); setSecondVideoExpanded(true); }}
              >
                Watch Now
              </div>
              <div className="landing_01__video__title__text">
                View Our Product Demo
              </div>
            </div>
          </div>
        </div>
      </div >
      <div className="home_01_content_try_cards" style={{ flexWrap: "wrap", justifyContent: "space-evenly", padding: "40px 0" }}>
        <Reveal visible1={visible2} visible2={visible2} hidden1={hidden2} hidden2={hidden2}>
          <AudioBtn url={HomeAudio} assistantName="HOME SERVICES" />
        </Reveal>
        <Reveal visible1={visible2} visible2={visible2} hidden1={hidden2} hidden2={hidden2}>
          <AudioBtn url={LegalAudio} assistantName="LEGAL SERVICES" />
        </Reveal>
        <Reveal visible1={visible2} visible2={visible2} hidden1={hidden2} hidden2={hidden2}>
          <AudioBtn url={FinancialAudio} assistantName="FINANCIAL SERVICES" />
        </Reveal>
        <Reveal visible1={visible2} visible2={visible2} hidden1={hidden2} hidden2={hidden2}>
          <AudioBtn url={InsuranceAudio} assistantName="INSURANCE SERVICES" />
        </Reveal>
      </div>
      <div className="landing_01">
        <div className="container" style={{ display: "flex", flexDirection: "column" }}>
          <div className="pricing_01__content__top">
            <div className="pricing_01__content__description">
              PRICING
            </div>
            <div className="pricing_01__content__title" style={{ marginBottom: "45px" }}>
              The Perfect Plan For Your Needs
            </div>
          </div>
          <div className="landing_01__pricing__section" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <PricingTable />
            <Reveal visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={hidden2}>
              <Calculator />
            </Reveal>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Landing01;